<template>
  <div class="tab-body-card" style="min-height: calc(100vh - 10vh)">
    <vue-element-loading
      :active="loading"
      :text="loadingText"
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <CCallout color="info">
      <v-select
        class="bg-white"
        dir="rtl"
        v-model="adviserSelected"
        :options="farmuserList"
        label="fullName"
        placeholder="کارمند مزرعه را انتخاب کنید"
      ></v-select>
    </CCallout>
    <CRow v-if="adviserSelected">
      <CCol md="6">
        <h4 class="text-warning"> زمین های من ...</h4>
        <div class="farmuser-farm-list" v-if="accessibleFarms.length > 0">
          <div
            class="farm-item"
            v-for="(item, index) of accessibleFarms"
            :key="index"
          >
            <img
              class="product-img"
              :src="item.productImage"
              @error="
                () =>
                  (item.productImage = require(`@/assets/img/farmImageMapBox.jpg`))
              "
            />
            <img
              :src="item.image"
              @error="
                () => (item.image = require(`@/assets/img/farmImageMapBox.jpg`))
              "
            />
            <ul>
              <li>
                <h4>نام زمین :</h4>
                <h1>{{ item.title }}</h1>
              </li>
              <li>
                <h4>محصول :</h4>
                <h1>{{ item.product }}</h1>
              </li>
              <li>
                <h4>تاریخ کشت :</h4>
                <h1>{{ item.cultivationsDate }}</h1>
              </li>
              <li>
                <h4>نوع کشت :</h4>
                <h1>{{ item.cultivationsType }}</h1>
              </li>
              <li>
                <h4>تاریخ کشت :</h4>
                <h1>{{ item.cultivationsDate }}</h1>
              </li>
              <li style="border-bottom: none">
                <h4><CIcon name="cilMap" /> آدرس :</h4>
                <h1>{{ item.stateCity }} _ {{ item.districtVilage }}</h1>
              </li>
              <li>
                <button
                  class="btn btn-success p-2"
                  style="margin-right: auto"
                  @click="AddFarmuserFarm(item.farmId, item.title)"
                >
                  افزودن دسترسی به زمین
                </button>
              </li>
            </ul>
          </div>
        </div>
        <no-data
          v-else
          :show="!loading"
          message="کارمند مزرعه به هیچ زمینی دسترسی ندارد"
        />
      </CCol>
      <CCol md="6">
        <h4 class="text-info">زمین های زیر نظر کارمند مزرعه ...</h4>
        <div class="farmuser-farm-list" v-if="inAccessibleFarms.length > 0">
          <div
            class="farm-item"
            v-for="(item, index) of inAccessibleFarms"
            :key="index"
          >
            <img
              class="product-img"
              :src="item.productImage"
              @error="
                () =>
                  (item.productImage = require(`@/assets/img/farmImageMapBox.jpg`))
              "
            />
            <img
              :src="item.image"
              @error="
                () => (item.image = require(`@/assets/img/farmImageMapBox.jpg`))
              "
            />
            <ul>
              <li>
                <h4>نام زمین :</h4>
                <h1>{{ item.title }}</h1>
              </li>
              <li>
                <h4>محصول :</h4>
                <h1>{{ item.product }}</h1>
              </li>
              <li>
                <h4>تاریخ کشت :</h4>
                <h1>{{ item.cultivationsDate }}</h1>
              </li>
              <li>
                <h4>نوع کشت :</h4>
                <h1>{{ item.cultivationsType }}</h1>
              </li>
              <li>
                <h4>تاریخ کشت :</h4>
                <h1>{{ item.cultivationsDate }}</h1>
              </li>
              <li>
                <h4><CIcon name="cilMap" /> آدرس :</h4>
                <h1>{{ item.stateCity }} _ {{ item.districtVilage }}</h1>
              </li>
              <li>
                <button
                  class="btn btn-danger p-2"
                  style="margin-right: auto"
                  @click="DeleteFarmuserFarm(item.id, item.title)"
                >
                  حذف دسترسی به زمین
                </button>
              </li>
            </ul>
          </div>
        </div>
        <no-data
          v-else
          :show="!loading"
          message="تمام زمین های شما در دسترس کارمند مزرعه می باشد"
        />
      </CCol>
    </CRow>
    <no-data
      v-else
      :show="!loading"
      message="کارمند مزرعه را از لیست بالا انتخاب کنید"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { apiUrlRoot } from "../../../constants/config";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import NoData from "../../../components/no-data.vue";

export default {
  components: { NoData, vSelect },
  data() {
    return {
      modelShowState: false,
      modalTitle: "",
      sumAreas: 0,
      loading: false,
      loadingText: "در حال دریافت اطلاعات ...",
      farmuserId: 0,
      adviserSelected: "",
      farmuserList: [],
      accessibleFarms: [],
      inAccessibleFarms: [],
      rootPat: apiUrlRoot,
    };
  },
  computed: {},
  watch: {
    farmuserId: function () {
      this.GetAllFarmerFarms();
    },
    adviserSelected: function () {
      this.farmuserId = this.adviserSelected.farmuserId;
    },
  },
  methods: {
    ...mapActions("connectionFarmerFarmuser", [
      "GetAllFarmerFarmuser",
      "FarmerFarmuserFarms",
      "FarmuserDeleteFarm",
      "FarmuserAddFarm",
    ]),
    async GetAllFarmuser() {
      this.farmuserId = 0;
      let result = await this.GetAllFarmerFarmuser({
        searchName: "",
      });
      this.farmuserList = result.data.map((item) => {
        return {
          farmuserId: item.farmuserId,
          fullName: `${item.fullName} (${item.phone})`,
        };
      });
    },
    async GetAllFarmerFarms() {
      this.accessibleFarms = [];
      this.inAccessibleFarms = [];
      this.loading = true;
      this.loadingText = "دریافت لیست زمین های شما  ...";
      let accessibleItems = await this.FarmerFarmuserFarms({
        farmuserId: this.farmuserId,
        canAdd: true,
      });
      this.accessibleFarms = accessibleItems.data.map((element) => {
        return {
          farmId: element.farmId,
          title: element.title,
          product: element.product,
          productImage: `${apiUrlRoot}productImage/${element.productImage}`,
          image: `${apiUrlRoot}Farm/${element.image}`,
          cultivationsType: element.cultivationsType,
          cultivationsDate: element.cultivationsDate,
          districtVilage: element.districtVilage,
          stateCity: element.stateCity,
        };
      });
      await this.GetAllFarmuserFarms();
    },
    async GetAllFarmuserFarms() {
      this.loadingText = "دریافت لیست زمین های کارمند مزرعه  ...";
      let inAccessibleItems = await this.FarmerFarmuserFarms({
        farmuserId: this.farmuserId,
        canAdd: false,
      });
      this.inAccessibleFarms = inAccessibleItems.data.map((element) => {
        return {
          id: element.id,
          farmId: element.farmId,
          title: element.title,
          product: element.product,
          productImage: `${apiUrlRoot}productImage/${element.productImage}`,
          image: `${apiUrlRoot}Farm/${element.image}`,
          cultivationsType: element.cultivationsType,
          cultivationsDate: element.cultivationsDate,
          districtVilage: element.districtVilage,
          stateCity: element.stateCity,
        };
      });
      this.loading = false;
    },
    async AddFarmuserFarm(farmId, farmTitle) {
      this.$confirm({
        message: `زمین ${farmTitle} را به کارمند مزرعه می دهید؟`,
        button: {
          no: "لغو",
          yes: "بله",
        },
        callback: async (confirm) => {
          if (confirm) {
            this.loading = true;
            this.loadingText = "در حال ثبت اطلاعات...";
            let result = await this.FarmuserAddFarm({
              farmuserId: this.farmuserId,
              farmId: farmId,
            });
            if (result.succeeded) {
              await this.GetAllFarmerFarms();
            } else {
              this.$notify({
                title: "پیام سیستم",
                text: `${result.message} <br /><br />`,
                type: "info",
              });
            }
            this.loading = false;
          }
        },
      });
    },
    async DeleteFarmuserFarm(id, farmTitle) {
      this.$confirm({
        message: `زمین ${farmTitle} را از دسترس کارمند مزرعه خارج میکنید؟`,
        button: {
          no: "لغو",
          yes: "بله",
        },
        callback: async (confirm) => {
          if (confirm) {
            this.loading = true;
            this.loadingText = "در حال ثبت اطلاعات...";
            let result = await this.FarmuserDeleteFarm({
              id: id,
            });
            if (result.succeeded) {
              this.GetAllFarmerFarms();
            } else {
              this.$notify({
                title: "پیام سیستم",
                text: `${result.message} <br /><br />`,
                type: "info",
              });
            }
            this.loading = false;
          }
        },
      });
    },
    getImagePath(fileName, path) {
      return `${apiUrlRoot}${path}/${fileName}`;
    },
  },
  mounted() {
    this.GetAllFarmuser();
  },
  created() {},
};
</script>

<style scoped>
.farmuser-farm-list {
  width: 100%;
  height: calc(100vh - 25vh);
  overflow-y: auto;
}
.farm-item {
  display: flex;
  position: relative;
  margin: 10px;
  box-shadow: 0px 0px 11px -6px #000;
  border-radius: 10px;
  padding: 5px;
}
.farm-item img {
  width: 11vw;
}
.farm-item .product-img {
  position: absolute;
  width: 2vw;
  right: 12vw;
  bottom: 1vh;
  height: auto;
}
.farm-item ul {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px;
  margin: 0px;
  height: 100%;
  flex-grow: 1;
}
.farm-item ul li {
  display: flex;
  width: 96%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px dashed #bbb;
  margin: 2px 8px;
  padding: 1px 0px;
}
.farm-item ul li:last-child {
  border-bottom: none;
}
.farm-item ul li h4 {
  font-size: 15px;
  margin: 0px;
  padding: 0px 4px;
}
.farm-item ul li h1 {
  font-size: 15px;
  color: #607d8b;
}
</style>


