import { render, staticRenderFns } from "./tipsList.vue?vue&type=template&id=2707dd2e&scoped=true"
import script from "./tipsList.vue?vue&type=script&lang=js"
export * from "./tipsList.vue?vue&type=script&lang=js"
import style0 from "./tipsList.vue?vue&type=style&index=0&id=2707dd2e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2707dd2e",
  null
  
)

export default component.exports