<template>
  <div class="tab-body-card" style="min-height: calc(100vh - 10vh)">
    <vSelect
      dir="rtl"
      v-model="selectedFarmUser"
      :options="farmuserOptions"
      placeholder="انتخاب متخصص..."
      label="label"
      track-by="value"
    ></vSelect>
    <tipsList :userId="selectedFarmUser.value" />
  </div>
</template>

<script>
import tipsList from "./subForm/tipsList";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapActions } from "vuex";
export default {
  components: {
    vSelect,
    tipsList,
  },
  data() {
    return {
      userId: 0,
      selectedFarmUser: { value: "0", label: "تمام کارمندان" },
      farmuserOptions: [],
    };
  },
  computed: {},
  methods: {
    ...mapActions("connectionFarmerFarmuser", ["GetAllFarmerFarmuser"]),

    async GetFarmUserFilter() {
      let result = await this.GetAllFarmerFarmuser({
        searchName: "",
      });
      result.data.forEach((element) => {
        this.farmuserOptions.push({
          value: element.farmuserId,
          label: `${element.fullName} (${element.phone}) تعداد زمین : ${element.farmCount} عدد`,
        });
      });
    },
  },
  mounted() {
    this.GetFarmUserFilter();
  },
  created() {},
};
</script>

<style>
</style>
