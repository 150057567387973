<template>
  <div>
    <body-with-header ads title="مدیریت کارمندان مزرعه">
      <CRow class="h-100">
        <vue-element-loading
          :active="loading"
          :text="loadingText"
          spinner="bar-fade-scale"
          color="var(--secondary)"
        />
        <CCol md="12">
          <CTabs fade variant="tabs">
            <CTab active>
              <template #title>
                <div class="y-center-g-10 justify-content-center">
                  <span>مدیریت کارمندان مزرعه</span>
                  <font-awesome-icon icon="history" />
                </div>
              </template>
              <farmuserManagementForm />
            </CTab>
            <CTab>
              <template #title>
                <div class="y-center-g-10 justify-content-center">
                  <span>زمین های کارمندان مزرعه</span>
                  <font-awesome-icon icon="users" />
                </div>
              </template>
              <farmerFarmuserFarmsForm />
            </CTab>
            <CTab>
              <template #title>
                <div class="y-center-g-10 justify-content-center">
                  <span>توصیه های کارمندان مزرعه</span>
                  <font-awesome-icon icon="user-md" />
                </div>
              </template>
              <farmuserTipsForm />
            </CTab>
          </CTabs>
        </CCol>
      </CRow>
    </body-with-header>
  </div>
</template>
  
  <script>
import BodyWithHeader from "../../components/bodyWithHeader.vue";
import farmuserManagementForm from "./farmuserManagement/farmuserManagementForm.vue";
import farmuserTipsForm from "./farmuserTips/farmuserTipsForm.vue";
import farmerFarmuserFarmsForm from "./farms/farmerFarmuserFarmsForm.vue";
export default {
  components: {
    BodyWithHeader,
    farmuserManagementForm,
    farmuserTipsForm,
    farmerFarmuserFarmsForm,
  },
  data() {
    return {
      loading: false,
      loadingText: "در حال بارگذاری...",
    };
  },
};
</script>
  